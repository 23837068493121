import axios from 'axios';
import { backAPI } from '../constant/constants';

//const SingupURL = dbConstants.adminAuth;
const adminAuthLogin = backAPI.adminAuthLogin;
const adminAuthLoginGoogle = backAPI.adminAuthLoginGoogle;
//const ProfileURL = dbConstants.userProfile;
// const auth_header = () => ({
// 	authorization: 'Bearer ' + window.localStorage.getItem('token'),
// });

const loginService = async (email, password) => {
	return await axios.post(adminAuthLogin, { email, password });
};

const signupAdmin = async (access_token) =>
	//await axios.post(SingupURL, { credential: access_token });
	{
		//const data = { access_token: access_token };
		return await axios.post(adminAuthLoginGoogle, access_token);
	};

/*const getProfile = async (email) =>
	await axios.post(ProfileURL, { email }, { headers: auth_header() });
*/
const authService = {
	signupAdmin,
	loginService,
	//getProfile,
};

export default authService;
