import './PageNotFound.css';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { paths, words } from '../../constant/constants';

export const PageNotFound = () => {
	const navigate = useNavigate();
	return (
		<div className='no-items-container'>
			{/* <h2 className='page-heading'>Error 404</h2> */}
			<h2 className='page-heading'>{words.PageNotFound}</h2>
			<button
				onClick={() => navigate(paths.indexPath)}
				className='explore-btn'
			>
				{words.backHome}
			</button>
		</div>
	);
};
