import React from 'react';
import { Home } from '../pages/Home/Home';
import { Cart } from '../pages/Cart/Cart';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Login } from '../pages/auth/Login/Login.jsx';
import { ProductListing } from '../pages/ProductListing/ProductListing';
import { ProductDetails } from '../pages/ProductDetails/ProductDetails';
import { RequiresAuth } from '../components/requires-auth/RequiresAuth';
import { Logout } from '../pages/auth/Logout/Logout.jsx'; //' /Auth/Logout/Logout';
import { Checkout } from '../pages/Checkout/Checkout';
import { UserProfile } from '../pages/UserProfile/UserProfile';
import { Profile } from '../pages/UserProfile/Profile/Profile';
import Orders from '../pages/Orders/Orders';
import { PageNotFound } from '../pages/PageNotFound/PageNotFound';
import { paths } from '../constant/constants';
import { Addresses } from '../pages/UserProfile/Addresses/Addresses';
import { Orders as UserOrders } from '../pages/UserProfile/Orders/Orders';
import Books from '../pages/Books/Books';
import AddBookForm from '../pages/Books/AddBookForm/AddBookForm';
import Genres from '../pages/Genres/Genres';
import AddGenreForm from '../pages/Genres/AddGenre/AddGenreForm';
export const NavRoutes = () => {
	return (
		<Routes>
			<Route
				path={paths.indexPath}
				element={
					<RequiresAuth>
						<Home />
					</RequiresAuth>
				}
			/>

			<Route
				path={paths.signupPath}
				element={<Navigate to={paths.loginPath} replace />}
			/>
			<Route path={paths.loginPath} element={<Login />} />
			<Route path={paths.logoutPath} element={<Logout />} />

			{/******/}
			<Route
				path={paths.booksListPath}
				element={
					<RequiresAuth>
						<ProductListing />
					</RequiresAuth>
				}
			/>
			<Route
				path={paths.bookDetailsPath + ':bookId'}
				element={
					<RequiresAuth>
						<ProductDetails />
					</RequiresAuth>
				}
			/>

			<Route
				path={paths.cartPath}
				element={
					<RequiresAuth>
						<Cart />
					</RequiresAuth>
				}
			/>
			<Route
				path={paths.checkoutPath}
				element={
					<RequiresAuth>
						<Checkout />
					</RequiresAuth>
				}
			/>
			{/* <Route
				path='/wishlist'
				element={
					 <RequiresAuth>
					<Wishlist />
					</RequiresAuth>
				}
			/> */}
			<Route
				path={paths.ordersPath}
				element={
					<RequiresAuth>
						<Orders />
					</RequiresAuth>
				}
			/>
			<Route
				path={paths.genresAdmin}
				element={
					<RequiresAuth>
						<Genres />
					</RequiresAuth>
				}
			/>
			<Route
				path={paths.booksAdmin}
				element={
					<RequiresAuth>
						<Books />
					</RequiresAuth>
				}
			/>
			<Route path={paths.addBookAdmin} element={<AddBookForm />} />
			<Route path={paths.addGenreAdmin} element={<AddGenreForm />} />

			<Route
				path={paths.profilePath}
				element={
					<RequiresAuth>
						<UserProfile />
					</RequiresAuth>
				}
			>
				<Route
					path={paths.profileDetailsPath}
					element={
						<RequiresAuth>
							<Profile />
						</RequiresAuth>
					}
				/>
				<Route
					path={paths.profileOrdersPath}
					element={<UserOrders />}
				/>
				<Route
					path={paths.profileAddressesPath}
					element={<Addresses />}
				/>
			</Route>

			<Route path={paths.notFoundPath} element={<PageNotFound />} />
		</Routes>
	);
};
