import './AddBookForm.css';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useData } from '../../../contexts/DataProvider';

import { paths, words } from '../../../constant/constants';

const AddBookForm = () => {
	const navigate = useNavigate();
	const [fileToUpload, setFileToUpload] = useState(null);
	const { state, addBook } = useData();
	const [loading, setLoading] = useState(false);
	const [bookForm, setBookForm] = useState({
		title: '',
		authors: '',
		genre: state.allGenres.length > 0 ? state.allGenres[0].genre : '',
		year: '',
		description: '',
		//imageUrl: '',
		isbn: '',
		price: '',
		availableCount: '',
		//pagesCount: '',
	});

	const handleFormSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);

		const formData = new FormData();
		formData.append('file', fileToUpload);
		formData.append('title', bookForm.title);
		formData.append('authors', bookForm.authors);
		formData.append('genre', bookForm.genre);
		formData.append('year', bookForm.year);
		formData.append('description', bookForm.description);
		formData.append('isbn', bookForm.isbn);
		formData.append('price', bookForm.price);
		formData.append('availableCount', bookForm.availableCount);
		//formData.append('pagesCount', bookForm.pagesCount);

		const isBookAdded = await addBook(formData);
		setLoading(false);
		if (isBookAdded) {
			setBookForm({
				title: '',
				authors: '',
				genre: '',
				year: '',
				description: '',
				imageUrl: '',
				isbn: '',
				price: '',
				availableCount: '',
				//pagesCount: '',
			});
			setFileToUpload(null);
			navigate(paths.booksAdmin);
		}
	};

	const selectOptions = state.allGenres.map((g, i) => (
		<option key={i} value={g.genre}>
			{g.genre}
		</option>
	));

	//return <select>{selectOptions}</select>;
	//const maxYear = new Date().getFullYear();

	return (
		<div className='book-modal-container '>
			<div className='book-input-container navBar-bk '>
				<h2>{words.AddBookFormHead}</h2>
				<form
					disabled={loading}
					onSubmit={
						handleFormSubmit
						//addAddressHandler();
						// setAddressForm({
						// 	name: '',
						// 	street: '',
						// 	city: '',
						// 	state: '',
						// 	country: '',
						// 	pincode: '',
						// 	phone: '',
						// });
						// setIsAddressModalOpen(false);/// navigate to books
					}
					className='input-containter'
				>
					<input
						disabled={loading}
						name='isbn'
						value={bookForm.isbn}
						//required
						onChange={(e) =>
							setBookForm({
								...bookForm,
								isbn: e.target.value,
							})
						}
						placeholder={words.AddBookFormISBN}
					/>
					<input
						disabled={loading}
						name='title'
						value={bookForm.title}
						required
						onChange={(e) =>
							setBookForm({
								...bookForm,
								title: e.target.value,
							})
						}
						placeholder={words.AddBookFormTitle}
					/>
					<input
						disabled={loading}
						name='authors'
						required
						value={bookForm.authors}
						onChange={(e) =>
							setBookForm({
								...bookForm,
								authors: e.target.value,
							})
						}
						placeholder={words.AddBookFormAuthors}
					/>
					<div className='selectGenre'>
						<label htmlFor='selectGenre'>
							{words.AddBookFormGenre}:
						</label>
						<select
							disabled={loading}
							name='genre'
							id='selectGenre'
							required
							value={bookForm.genre}
							onChange={(e) => {
								setBookForm({
									...bookForm,
									genre: e.target.value,
								});
							}}
							placeholder={`Choose Genre`}
						>
							{/* <option value={null}>
								{words.addBookFormNullGenre}
							</option> */}
							{selectOptions}
						</select>
					</div>
					{/* <input
						disabled={loading}
						name='genre'
						required
						value={bookForm.genre}
						onChange={(e) => {
							setBookForm({
								...bookForm,
								genre: e.target.value,
							});
						}}
						placeholder={`Enter Genre`}
					/> */}
					<input
						disabled={loading}
						name='year'
						//required
						type='year'
						//defaultValue={maxYear}
						//min={1900}
						//max={maxYear}
						value={bookForm.year}
						onChange={(e) =>
							setBookForm({
								...bookForm,
								year: e.target.value,
							})
						}
						placeholder={words.AddBookFormYear}
					/>
					<textarea
						disabled={loading}
						name='description'
						value={bookForm.description}
						//required
						minLength='10'
						onChange={(e) =>
							setBookForm({
								...bookForm,
								description: e.target.value,
							})
						}
						placeholder={words.AddBookFormDescr}
					/>

					<input
						disabled={loading}
						name='price'
						value={bookForm.price}
						//required
						type='number'
						min={0}
						onChange={(e) =>
							setBookForm({
								...bookForm,
								price: e.target.value,
							})
						}
						placeholder={words.AddBookFormPrice}
					/>
					<input
						disabled={loading}
						name='availableCount'
						value={bookForm.availableCount}
						required
						type='number'
						min={0}
						onChange={(e) =>
							setBookForm({
								...bookForm,
								availableCount: e.target.value,
							})
						}
						placeholder={words.AddBookFormAvailable}
					/>
					{/* <input
						disabled={loading}
						name='pagesCount'
						value={bookForm.pagesCount}
						//required
						type='number'
						min={0}
						onChange={(e) =>
							setBookForm({
								...bookForm,
								pagesCount: e.target.value,
							})
						}
						placeholder={words.AddBookFormPages}
					/> */}
					<div className='file'>
						<label htmlFor='image'>{words.AddBookFormImage}</label>
						<input
							disabled={loading}
							className='selectImage'
							//className='flex justify-center w-auto mx-auto'
							name='image'
							required
							type='file'
							id='image'
							accept='image/*'
							placeholder={`choose an image`}
							onChange={(e) => setFileToUpload(e.target.files[0])}
						/>
					</div>

					<input
						disabled={loading}
						className='submit main_btn_bk'
						type='submit'
						value={words.AddBookFormSubmit}
					/>
				</form>
				{/*	<div className='btn-container'>
					<button onClick={() => setIsAddressModalOpen(false)}>
						{words.addressFormCancel}
					</button>
				 <button
						onClick={() => {
							setBookForm({ ...dummyAddress });
						}}
					>
						Add Dummy Data
					</button> 
				</div>*/}
			</div>
		</div>
	);
};
export default AddBookForm;
