import React from 'react';
import { MdDelete } from 'react-icons/md';
//import { AiOutlineHeart } from 'react-icons/ai';
//import { AiFillHeart } from 'react-icons/ai';
import './CartListing.css';
//import Tilt from 'react-parallax-tilt';

import { useUserData } from '../../../../contexts/UserDataProvider';
import { Link } from 'react-router-dom';
import { paths, words } from '../../../../constant/constants';

export const CartListing = () => {
	const { userDataState, removeFromCartHandler } = useUserData();

	return (
		<div className='cart-products-container'>
			{userDataState.cartBooks.map((book) => (
				<div className='product-card' key={book._id}>
					<Link to={`${paths.bookDetailsPath}${book._id}`}>
						<div className='product-card-image'>
							{/* <Tilt
								transitionSpeed={2000}
								tiltMaxAngleX={15}
								tiltMaxAngleY={15}
								scale={1.18}
							> */}
							<img src={book.imageUrl} alt={book.title} />
							{/* </Tilt> */}
						</div>
					</Link>
					<div className='product-description'>
						<h3>{book.title}</h3>
						{/* <p>
							{words.price}: ${book.price}
						</p> */}
						{/* <p>Size: {book.size}</p> */}
					</div>
					<div className='button-section'>
						<div className='count-btn-container'>
							{/*<button
								disabled={cartLoading}
								className='counter-btn'
								onClick={() =>
									cartCountHandler(book, 'decrement')
								}
							>
								-
							</button>
							<span>{book.qty}</span>
							<button
								disabled={cartLoading}
								className='counter-btn'
								onClick={() =>
									cartCountHandler(book, 'increment')
								}
							>
								+
							</button>
						</div>
						<div className='secondary-btn-section'>*/}
							<button
								className=' warning_bk'
								onClick={() => removeFromCartHandler(book)}
							>
								<MdDelete size={25} />
								{words.removeFromCart}
							</button>
							{/*
								//!isProductInWishlist(book)
								false ? (
									<AiOutlineHeart
										size={25}
										//	onClick={() => wishlistHandler(book)}
									/>
								) : (
									<AiFillHeart
										size={25}
										//onClick={() => wishlistHandler(book)}
									/>
								)
							*/}
						</div>
					</div>
				</div>
			))}
		</div>
	);
};
