import './AddGenreForm.css';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useData } from '../../../contexts/DataProvider';

import { paths, words } from '../../../constant/constants';
import toast from 'react-hot-toast';

const AddGenreForm = () => {
	const navigate = useNavigate();
	const { state, addGenre } = useData();
	const [loading, setLoading] = useState(false);
	const [genreForm, setGenreForm] = useState({
		genre: '',
	});
	const checkGenreExists = () =>
		state.allGenres.some((g) => g.genre === genreForm.genre);

	const handleFormSubmit = async (e) => {
		e.preventDefault();

		if (checkGenreExists()) {
			toast.error('Genre is already exists');
			return false;
		}
		setLoading(true);
		const formData = new FormData();
		formData.append('genre', genreForm.genre);

		const isGenreAdded = await addGenre(formData);
		setLoading(false);
		if (isGenreAdded) {
			setGenreForm({
				genre: '',
			});
			navigate(paths.genresAdmin);
		}
	};

	return (
		<div className='genre-modal-container '>
			<div className='genre-input-container navBar-bk '>
				<h2>{words.AddGenreFormHead}</h2>
				<form
					disabled={loading}
					onSubmit={handleFormSubmit}
					className='input-containter'
				>
					<input
						disabled={loading}
						name='genre'
						value={genreForm.genre}
						//required
						onChange={(e) =>
							setGenreForm({
								...genreForm,
								genre: e.target.value,
							})
						}
						placeholder={words.AddGenreFormGenre}
					/>

					<input
						disabled={loading}
						className='submit  main_btn_bk'
						type='submit'
						value={words.AddGenreFormSubmit}
					/>
				</form>
			</div>
		</div>
	);
};
export default AddGenreForm;
