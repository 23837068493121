import React, { useMemo, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { useData } from '../../contexts/DataProvider';
import { Box, Button /*, MenuItem*/ } from '@mui/material';
//nested data is ok, see accessorKeys in ColumnDef below
import './Order.css';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { RefreshOutlined } from '@mui/icons-material';

import { ExportToCsv } from 'export-to-csv'; //or use your library of choice her
import { words } from '../../constant/constants';
import { dateFormat, timeFormat } from '../../helpers/formatData';
import toast from 'react-hot-toast';

const Orders = () => {
	const [disabledButton, setDisabledButton] = useState(false);
	//should be memoized or stable
	const showAddress = (address) => {
		let orderAddress = address.country ? `${address.country},` : '';
		orderAddress += address.state ? `${address.state},` : '';
		orderAddress += address.city ? `${address.city},` : '';
		orderAddress += address.street ? `${address.street}` : '';
		orderAddress += ' - ' + address.phone;
		return orderAddress;
	};

	const {
		state,
		loading,
		updateOrderStatusHandler,
		setLoading,
		getOrdersList,
	} = useData();
	const list = //userDataState.orders.map((o) => {
		state.orders.map((o) => {
			return {
				//key: o._id,
				orderId: o.orderId,
				name: o.user.name,
				email: o.user.email,
				address: showAddress(o.address),
				status: o.status,
				creationDate:
					dateFormat(new Date(o.creationDate)) +
					' ' +
					timeFormat(new Date(o.creationDate)), // parseDate(new Date(o.creationDate)),
				handleDate: o.handleDate
					? dateFormat(new Date(o.handleDate)) +
					  ' ' +
					  timeFormat(new Date(o.handleDate))
					: '',
				_id: o.orderId,
				archived: o.archived,
				books: o.booksInfo,
			};
		});
	const columns = useMemo(
		() => [
			{
				accessorKey: 'orderId', //access nested data with dot notation
				header: words.orderOrderId,
				size: 100,
			},
			{
				accessorKey: 'name', //access nested data with dot notation
				header: words.orderCustName,
				size: 150,
			},
			{
				accessorKey: 'email',
				header: words.orderCustEmail,
				size: 150,
			},
			{
				accessorKey: 'address', //normal accessorKey
				header: words.orderCustAddress,
				size: 300,
			},
			{
				accessorKey: 'status', //normal accessorKey
				header: words.orderTStatus,
				size: 100,
			},
			{
				accessorKey: 'creationDate', //normal accessorKey
				header: words.orderCreationDate,
				size: 200,
			},
			{
				accessorKey: 'handleDate', //normal accessorKey
				header: words.orderHandleDate,
				size: 200,
			},
			// {
			// 	accessorKey: 'city',
			// 	header: 'City',
			// 	size: 150,
			// },
			// {
			// 	accessorKey: 'state',
			// 	header: 'State',
			// 	size: 150,
			// },
		],
		[]
	);

	const columnsCSV = columns
		.map((c) => c.header)
		.concat(['NoOfBooks', 'Books']);
	const csvOptions = {
		fieldSeparator: ',',
		quoteStrings: '"',
		decimalSeparator: '.',
		showLabels: true,
		useBom: true,
		useKeysAsHeaders: false,
		headers: columnsCSV,
	};

	const csvExporter = new ExportToCsv(csvOptions);

	const handleExportRows = (rows) => {
		csvExporter.generateCsv(
			rows.map((row) => ({
				orderId: row.original.orderId,
				name: row.original.name,
				email: row.original.email,
				address: row.original.address,
				status: row.original.status,
				creationDate: row.original.creationDate,
				handleDate: row.original.handleDate,
				NoOfBooks: row.original.books.length,
				books: row.original.books.map((b) => b.title), //reduce((a, b) => (a += `${b.title}, `),''),
			}))
		);
	};

	const handleExportData = () => {
		csvExporter.generateCsv(
			list.map((o) => {
				return {
					orderId: o.orderId,
					name: o.name,
					email: o.email,
					address: o.address,
					status: o.status,
					creationDate: o.creationDate,
					handleDate: o.handleDate,
					NoOfBooks: o.books.length,
					books: o.books.map((b) => b.title), //reduce((a, b) => (a += `${b.title}, `), ''),
				};
			})
		);
	};
	const handleRefreshOrders = async () => {
		setDisabledButton(() => true);
		setLoading(true);
		await getOrdersList();
		toast.success('تم تحديث قائمة الطلبات');
		setDisabledButton(false);
		setLoading(false);
	};
	return (
		<MaterialReactTable
			title='Products'
			options={{
				title: 'orders',
				enableStickyHeader: true,
				headerStyle: {
					backgroundColor: 'black',
					fontWeight: 'bold',
				},
			}}
			columns={columns}
			data={list}
			enableExpandAll={false}
			muiTableBodyCellProps={{ align: 'center', direction: 'rtl' }}
			enableRowSelection
			enableStickyHeader
			muiTableHeadCellProps={{
				align: 'center',
				sx: (theme) => ({
					background: '#f5f7f9', //'rgba(52, 210, 235, 0.1)',
					borderRight: '1px solid rgba(224,224,224,1)',
					color: theme.palette.text.primary,
				}),
			}}
			// positionToolbarAlertBanner='bottom'
			renderTopToolbarCustomActions={({ table }) => (
				<Box
					sx={{
						display: 'flex',
						gap: '1rem',
						p: '0.5rem',
						flexWrap: 'wrap',
					}}
				>
					<Button
						color='success'
						disabled={disabledButton}
						onClick={() => handleRefreshOrders()}
						variant='outlined'
						style={{ fontWeight: 'bold' }}
						startIcon={<RefreshOutlined />}
					>
						{words.orderRefresh}
					</Button>
					<Button
						disabled={disabledButton}
						color='primary'
						//export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
						onClick={handleExportData}
						startIcon={<FileDownloadIcon />}
						variant='outlined'
						style={{ fontWeight: 'bold' }}
					>
						{words.orderExportAll}
					</Button>
					{/* <Button
						disabled={
							table.getPrePaginationRowModel().rows.length === 0
						}
						color='success'
						//export all rows, including from the next page, (still respects filtering and sorting)
						onClick={() =>
							handleExportRows(
								table.getPrePaginationRowModel().rows
							)
						}
						startIcon={<FileDownloadIcon />}
						variant='contained'
					>
						Export All Rows
					</Button> */}
					<Button
						disabled={
							table.getRowModel().rows.length === 0 ||
							disabledButton
						}
						color='warning'
						//export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
						onClick={() =>
							handleExportRows(table.getRowModel().rows)
						}
						startIcon={<FileDownloadIcon />}
						variant='outlined'
						style={{ fontWeight: 'bold' }}
					>
						{words.orderExportPage}
					</Button>
					<Button
						disabled={
							(!table.getIsSomeRowsSelected() &&
								!table.getIsAllRowsSelected()) ||
							disabledButton
						}
						color='secondary'
						//only export selected rows
						onClick={() =>
							handleExportRows(table.getSelectedRowModel().rows)
						}
						startIcon={<FileDownloadIcon />}
						variant='outlined'
						style={{ fontWeight: 'bold' }}
					>
						{words.orderExportSelect}
					</Button>
				</Box>
			)}
			renderDetailPanel={({ row }) => (
				<div className='ordered-items-card'>
					{/* <h3>Books:</h3> */}
					<div
						className='products-container'
						// style={{ display: 'grid', paddingLeft: '4rem' }}
					>
						{row.original.books.map((b, i) => (
							// <span>{b}</span>
							<div className='products-card' key={i}>
								<img src={b.imageUrl} alt={b.title} />
								<div className='description'>
									<span className='bookTitle'>{b.title}</span>
									<span className='authors'>{b.authors}</span>
								</div>
							</div>
						))}
					</div>
				</div>
			)}
			enableRowActions
			renderRowActions={({ row }) => (
				<div
					style={{
						display: 'flex',
						justifyContent: 'end',
						flexWrap: 'nowrap',
						gap: '0.5rem',
						height: '2rem',
					}}
				>
					{row.original.status === 'pending' && (
						<Button
							variant='outlined'
							color='success'
							disabled={disabledButton}
							style={{ fontWeight: 'bold' }}
							onClick={() => {
								updateOrderStatusHandler(
									row.original._id,
									'accepted'
								);
							}}
						>
							{words.orderAccept}
						</Button>
					)}
					{row.original.status === 'pending' && (
						<Button
							variant='outlined'
							color='error'
							disabled={disabledButton}
							style={{ fontWeight: 'bold' }}
							onClick={() => {
								updateOrderStatusHandler(
									row.original._id,
									'rejected'
								);
							}}
						>
							{words.orderReject}
						</Button>
					)}
					{(row.original.status === 'rejected' ||
						row.original.status === 'accepted') && (
						<Button
							variant='outlined'
							color='info'
							disabled={disabledButton}
							style={{ fontWeight: 'bold' }}
							onClick={() => {
								updateOrderStatusHandler(
									row.original._id,
									'pending'
								);
							}}
						>
							{words.orderpend}
						</Button>
					)}
				</div>
			)}
			/*renderRowActionMenuItems={({ row, closeMenu }) => [
				row.original.status === 'pending' && (
					<MenuItem
						key={0}
						disabled={disabledButton}
						onClick={() => {
							// View profile logic...
							updateOrderStatusHandler(
								row.original._id,
								'accepted'
							);
							closeMenu();
						}}
						sx={{ m: 0 }}
					>
						{words.orderAccept}
					</MenuItem>
				),
				row.original.status === 'pending' && (
					<MenuItem
						key={1}
						disabled={disabledButton}
						onClick={() => {
							// View profile logic...
							updateOrderStatusHandler(
								row.original._id,
								'rejected'
							);
							closeMenu();
						}}
						sx={{ m: 0 }}
					>
						{words.orderReject}
					</MenuItem>
				),
				(row.original.status === 'rejected' ||
					row.original.status === 'accepted') && (
					<MenuItem
						key={2}
						disabled={disabledButton}
						onClick={() => {
							// Send email logic...
							updateOrderStatusHandler(
								row.original._id,
								'pending'
							);

							closeMenu();
						}}
						sx={{ m: 0 }}
					>
						{words.orderPending}
					</MenuItem>
				),
			]}*/
			state={{ isLoading: loading }}
		/>
	);
};

export default Orders;
