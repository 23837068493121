import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
//import { makeServer } from './server';
import { BrowserRouter as Router } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { DataProvider } from './contexts/DataProvider';
import { AuthProvider } from './contexts/AuthProvider';
import { UserProvider } from './contexts/UserDataProvider';
import { AddressProvider } from './contexts/AddressProvider';

// Call make Server
//makeServer();
const root = ReactDOM.createRoot(document.getElementById('root'));

const CLIENT_ID =
	'434310352973-sekfttap9ervpq840ct8gr2438sid0m0.apps.googleusercontent.com';

root.render(
	//<React.StrictMode>
	<Router>
		<GoogleOAuthProvider clientId={CLIENT_ID}>
			<AuthProvider>
				<DataProvider>
					<UserProvider>
						<AddressProvider>
							<App />
						</AddressProvider>
					</UserProvider>
				</DataProvider>
			</AuthProvider>
		</GoogleOAuthProvider>
	</Router>
	//</React.StrictMode>
);
