import React, { createContext, useContext, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { googleLogout } from '@react-oauth/google';

//import { loginService } from '../services/auth-services/loginService';
import authServices from '../services/authServices';
import { notificationMessages, paths } from '../constant/constants';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
	const [currentPage, setCurrentPage] = useState('profile');

	const [loginCredential, setLoginCredential] = useState({
		email: '',
		password: '',
	});
	const [error, setError] = useState('');
	const [loginLoading, setLoginLoading] = useState(false);
	const encodedToken = localStorage.getItem('token');
	const firstName = localStorage.getItem('firstName');
	const lastName = localStorage.getItem('lastName');
	const email = localStorage.getItem('email');
	//const roles = localStorage.getItem('roles');
	const navigate = useNavigate();
	const location = useLocation();

	const [auth, setAuth] = useState(
		encodedToken
			? {
					token: encodedToken,
					isAuth: true,
					firstName,
					lastName,
					email,
					//isAdminUser: roles === 'AdminRole' ? true : false,
			  }
			: { token: '', isAuth: false }
	);

	// const loginGoogleToken = async (codeResponse) => {
	// 	try {
	// 		setLoginLoading(true);
	// 		setError('');
	// 		//setLoginCredential({ email, password });
	// 		const response = await authServices.signupAdmin(
	// 			codeResponse.access_token
	// 		);
	// 		//loginService(codeResponse.access_token);
	// 		if (
	// 			//(response.success) {
	// 			response.status === 200
	// 		) {
	// 			setLoginLoading(false);

	// 			const user = response.data.data;
	// 			const encodedToken = response.data.token;
	// 			const firstName = user.given_name;
	// 			const lastName = user.family_name;
	// 			const email = user.email;
	// 			toast.success(
	// 				`${notificationMessages.loginSuccess} ${user.given_name}!`
	// 			);
	// 			setAuth({
	// 				token: encodedToken,
	// 				isAuth: true,
	// 				firstName,
	// 				lastName,
	// 				email,
	// 			});

	// 			localStorage.setItem('token', encodedToken);
	// 			localStorage.setItem('isAuth', true);
	// 			localStorage.setItem('firstName', firstName);
	// 			localStorage.setItem('lastName', lastName);
	// 			localStorage.setItem('email', email);
	// 			navigate(location?.state?.from.pathname || paths.indexPath);
	// 		}
	// 	} catch (error) {
	// 		setLoginLoading(false);
	// 		//setError(error);
	// 		toast.error(notificationMessages.loginFailed);
	// 	} finally {
	// 		setLoginLoading(false);
	// 	}
	// };

	// const googleLogin = useGoogleLogin({
	// 	onSuccess: (codeResponse) => {
	// 		loginGoogleToken(codeResponse);
	// 	},
	// 	onError: (error) => console.log('Login Failed:', error),
	// });

	// const loginHandler = (e) => {
	// 	e.preventDefault();
	// 	googleLogin();
	// };
	const loginHandler = async (e, email, password) => {
		e.preventDefault();
		try {
			setLoginLoading(true);
			setError('');
			setLoginCredential({ email, password });
			const response = await authServices.loginService(email, password);
			if (response.status === 200) {
				if (response.data.status === 'success') {
					setLoginLoading(false);
					const user = response.data.data;
					const encodedToken = response.data.token;
					const firstName = user.given_name;
					const lastName = user.family_name;
					const email = user.email;
					toast.success(`Welcome back, ${firstName}!`);
					setAuth({
						token: encodedToken,
						isAuth: true,
						firstName,
						lastName,
						email,
					});

					localStorage.setItem('token', encodedToken);
					localStorage.setItem('isAuth', true);
					localStorage.setItem('firstName', firstName);
					localStorage.setItem('lastName', lastName);
					localStorage.setItem('email', email);
					setLoginCredential({ email: '', password: '' });
					navigate(location?.state?.from.pathname || paths.indexPath);
				} else {
					toast.error(response.data.message);
				}
			}
		} catch (error) {
			setLoginLoading(false);
			//setError(error.response.data.errors[0]);
			toast.error(notificationMessages.loginFailed);
		} finally {
			setLoginLoading(false);
		}
	};

	const logoutHandler = () => {
		localStorage.removeItem('token');
		localStorage.removeItem('isAuth');
		localStorage.removeItem('firstName');
		localStorage.removeItem('lastName');
		localStorage.removeItem('email');

		setAuth({ token: '', isAuth: false });
		googleLogout();
		toast.success(notificationMessages.logoutSuccessMsg);
	};
	return (
		<AuthContext.Provider
			value={{
				auth,
				setAuth,
				loginHandler,
				logoutHandler,
				error,
				setError,
				loginLoading,
				setLoginLoading,
				loginCredential,
				setLoginCredential,
				setCurrentPage,
				currentPage,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuth = () => useContext(AuthContext);
