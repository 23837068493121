import React, { useMemo, useCallback } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { useData } from '../../contexts/DataProvider';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';

import './books.css';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Book from '@mui/icons-material/Book';

import { ExportToCsv } from 'export-to-csv';
import { useNavigate } from 'react-router-dom';
import { paths, words } from '../../constant/constants';

const Books = () => {
	const navigate = useNavigate();
	const { state, loading, updateBook, deleteBook } = useData();

	const list = state.allBooksFromApi.map((b) => {
		return {
			key: b._id,
			_id: b._id,
			title: b.title,
			authors: b.authors,
			genre: b.genre,
			year: b.year,
			description: b.description,
			imageUrl: b.imageUrl,
			isbn: b.isbn,
			price: b.price,
			availableCount: b.availableCount,
		};
	});
	const genreSelectOption = state.allGenres.map((g) => ({
		value: g.genre,
		text: g.genre,
	}));

	let columns = useMemo(
		() => [
			{
				accessorKey: 'isbn',
				header: 'ISBN',
				size: 100,
			},
			{
				accessorKey: 'title',
				header: words.bookTitle,
				size: 350,
			},
			{
				accessorKey: 'authors',
				header: words.bookAuthers,
				size: 300,
			},
			{
				accessorKey: 'genre',
				header: words.genre,
				size: 100,
				editVariant: 'select',
				editSelectOptions: genreSelectOption,
			},
			{
				accessorKey: 'availableCount',
				header: words.bookAvailable,
				size: 100,
			},
			{
				accessorKey: 'price',
				header: words.bookPrice,
				size: 100,
			},

			{
				accessorKey: 'description',
				header: words.bookDescr,
				size: 350,
			},
		],
		[
			genreSelectOption,
			/*list*/
		]
	);

	const columnsCSV = columns.map((c) => c.header).concat(['ImageURL']);
	const csvOptions = {
		fieldSeparator: ',',
		quoteStrings: '"',
		decimalSeparator: '.',
		showLabels: true,
		useBom: true,
		useKeysAsHeaders: false,
		headers: columnsCSV,
	};

	const csvExporter = new ExportToCsv(csvOptions);

	const handleExportRows = (rows) => {
		csvExporter.generateCsv(
			rows.map((row) => ({
				isbn: row.original.isbn,
				title: row.original.title,
				authors: row.original.authors,
				genre: row.original.genre,
				Available: row.original.Available,
				price: row.original.price,
				Description: row.original.description,
				ImageURL: row.original.imageUrl,
			}))
		);
	};

	const handleExportData = () => {
		csvExporter.generateCsv(
			list.map((b) => {
				return {
					isbn: b.isbn,
					title: b.title,
					authors: b.authors,
					genre: b.genre,
					Available: b.Available,
					price: b.price,
					Description: b.description,
					ImageURL: b.imageUrl,
				};
			})
		);
	};

	const handleSaveRow = ({ exitEditingMode, row, values }) => {
		const newBook = {
			_id: row.original._id,
			...values,
		};
		updateBook(newBook);
		exitEditingMode();
	};

	const handleDeleteRow = useCallback(
		(row) => {
			if (
				!window.confirm(
					`Are you sure you want to delete ${row.getValue('title')}`
				)
			) {
				return;
			}
			deleteBook(row.original);
		},
		[
			/*list*/
		]
	);

	return (
		<MaterialReactTable
			columns={columns}
			data={list}
			enableEditing
			onEditingRowSave={handleSaveRow}
			renderRowActions={({ row, table }) => (
				<Box sx={{ display: 'flex', gap: '1rem' }}>
					<Tooltip arrow placement='left' title='Edit'>
						<IconButton onClick={() => table.setEditingRow(row)}>
							<Edit />
						</IconButton>
					</Tooltip>
					<Tooltip arrow placement='right' title='Delete'>
						<IconButton
							color='error'
							onClick={(e) => {
								e.preventDefault();
								handleDeleteRow(row);
							}}
						>
							<Delete />
						</IconButton>
					</Tooltip>
				</Box>
			)}
			enableRowSelection
			// positionToolbarAlertBanner='bottom'
			enableStickyHeader
			muiTableHeadCellProps={{
				align: 'center',
				sx: (theme) => ({
					background: '#f5f7f9', //'rgba(52, 210, 235, 0.1)',
					borderRight: '1px solid rgba(224,224,224,1)',
					color: theme.palette.text.primary,
					direction: 'rtl',
					justifyContent: 'center',
				}),
			}}
			muiTableBodyCellProps={{ align: 'center', direction: 'rtl' }}
			enableStickyFooter
			renderTopToolbarCustomActions={({ table }) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					{/* <Typography variant='h4'>Books:</Typography> */}
					<Box
						sx={{
							display: 'flex',
							gap: '1rem',
							p: '0.5rem',
							flexWrap: 'wrap',
						}}
					>
						<Button
							color='success'
							onClick={() => navigate(paths.addBookAdmin)}
							variant='outlined'
							style={{
								fontWeight: 'bold',
							}}
							startIcon={<Book />}
						>
							{words.bookNew}
						</Button>
						<Button
							color='primary'
							//export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
							onClick={handleExportData}
							startIcon={<FileDownloadIcon />}
							variant='outlined'
							style={{ fontWeight: 'bold' }}
						>
							{words.bookExportAll}
						</Button>
						<Button
							disabled={table.getRowModel().rows.length === 0}
							color='warning'
							//export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
							onClick={() =>
								handleExportRows(table.getRowModel().rows)
							}
							startIcon={<FileDownloadIcon />}
							variant='outlined'
							style={{ fontWeight: 'bold' }}
						>
							{words.bookExportPage}
						</Button>
						<Button
							disabled={
								!table.getIsSomeRowsSelected() &&
								!table.getIsAllRowsSelected()
							}
							color='secondary'
							//only export selected rows
							onClick={() =>
								handleExportRows(
									table.getSelectedRowModel().rows
								)
							}
							startIcon={<FileDownloadIcon />}
							variant='outlined'
							style={{ fontWeight: 'bold' }}
						>
							{words.bookExportSelect}
						</Button>
					</Box>
				</div>
			)}
			enableExpandAll={false}
			renderDetailPanel={({ row }) => (
				<div className='expand-css bg-light-grey'>
					<div className='book-img-descr'>
						<img
							className='image'
							src={row.original.imageUrl}
							alt={row.original.title}
						/>
					</div>
				</div>
			)}
			state={{ isLoading: loading }}
		/>
	);
};

export default Books;
