import './AddressModal.css';
import React from 'react';

import { useAddress } from '../../../../contexts/AddressProvider';
import { words } from '../../../../constant/constants';

export const AddressModal = () => {
	const {
		setIsAddressModalOpen,
		addressForm,
		setAddressForm,
		isEdit,
		setIsEdit,
		addAddressHandler,
		updateAddressHandler,
	} = useAddress();

	const resetForm = () =>
		setAddressForm({
			name: '',
			street: '',
			city: '',
			state: '',
			country: '',
			pincode: '',
			phone: '',
		});

	return (
		<div className='address-modal-container'>
			<div className='address-input-container'>
				<h1>{words.addressForm}</h1>
				<form
					onSubmit={(e) => {
						if (!isEdit) {
							e.preventDefault();
							addAddressHandler();
							resetForm();
							setIsAddressModalOpen(false);
						} else {
							e.preventDefault();
							updateAddressHandler();
							resetForm();
							setIsAddressModalOpen(false);
							setIsEdit(false);
						}
					}}
					className='input-containter'
				>
					<input
						name='name'
						value={addressForm.name}
						required
						onChange={(e) =>
							setAddressForm({
								...addressForm,
								name: e.target.value,
							})
						}
						placeholder={words.addressFormName}
					/>
					<input
						required
						value={addressForm.street}
						onChange={(e) =>
							setAddressForm({
								...addressForm,
								street: e.target.value,
							})
						}
						placeholder={words.addressFormStreet}
					/>
					<input
						name='city'
						required
						value={addressForm.city}
						onChange={(e) =>
							setAddressForm({
								...addressForm,
								city: e.target.value,
							})
						}
						placeholder={words.addressFormCity}
					/>
					<input
						name='state'
						//required
						value={addressForm.state}
						onChange={(e) =>
							setAddressForm({
								...addressForm,
								state: e.target.value,
							})
						}
						placeholder={words.addressFormState}
					/>
					<input
						name='country'
						value={addressForm.country}
						//required
						onChange={(e) =>
							setAddressForm({
								...addressForm,
								country: e.target.value,
							})
						}
						placeholder={words.addressFormCountry}
					/>
					{/* <input
						name='pincode'
						value={addressForm.pincode}
						required
						onChange={(e) =>
							setAddressForm({
								...addressForm,
								pincode: e.target.value,
							})
						}
						placeholder='Enter Pincode'
					/> */}
					<input
						name='phone'
						value={addressForm.phone}
						required
						onChange={(e) =>
							setAddressForm({
								...addressForm,
								phone: e.target.value,
							})
						}
						placeholder={words.addressFormPhone}
						minLength='10'
						maxLength='10'
					/>
					<input
						className='submit'
						type='submit'
						value={words.addressFormSave}
					/>
					<input
						className='button'
						type='button'
						onClick={() => {
							resetForm();
							setIsAddressModalOpen(false);
						}}
						value={words.addressFormCancel}
					/>
				</form>
				{/*	<div className='btn-container'>
					<button onClick={() => setIsAddressModalOpen(false)}>
						{words.addressFormCancel}
					</button>
				 <button
						onClick={() => {
							setAddressForm({ ...dummyAddress });
						}}
					>
						Add Dummy Data
					</button> 
				</div>*/}
			</div>
		</div>
	);
};
