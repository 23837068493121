import { useData } from '../../contexts/DataProvider';
import { useUserData } from '../../contexts/UserDataProvider';
import './Checkout.css';
import { AddressSection } from './components/AddressSection/AddressSection';
import { OrderSummary } from './components/OrderSummary/OrderSummary';
import { words } from '../../constant/constants';
import NoItemInCart from '../Cart/components/NoItemInCart';

export const Checkout = () => {
	const { userDataState } = useUserData();
	const { loading } = useData();
	return (
		!loading &&
		(userDataState.cartBooks.length ? (
			<div>
				<h1 className='page-heading main_text_color'>
					{words.checkout}
				</h1>
				<div className='checkout-container'>
					<AddressSection />
					<OrderSummary />
				</div>
			</div>
		) : (
			<NoItemInCart />
		))
	);
};
