import './ProductDetails.css';
import React from 'react';
import { useParams } from 'react-router-dom';

import { useData } from '../../contexts/DataProvider';
import { ProductImage } from './components/ProductImage/ProductImage';
import { ProductDescription } from './components/ProductDescription/ProductDescription';

export const ProductDetails = () => {
	const { state, loading } = useData();
	const { bookId } = useParams();

	if (state.allBooksFromApi) {
		var selectedBook = state.allBooksFromApi?.find(
			({ _id }) => _id.toString() === bookId
		);
	}

	return (
		!loading &&
		selectedBook && (
			<>
				<div className='products-page-container'>
					<div style={{ flexGrow: 2 }}>
						<ProductImage selectedBook={selectedBook} />
					</div>
					<div style={{ flexGrow: 3 }}>
						<ProductDescription selectedBook={selectedBook} />
					</div>
				</div>
			</>
		)
	);
};
