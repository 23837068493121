import {
	createContext,
	useContext,
	useEffect,
	useReducer,
	useState,
} from 'react';
import toast from 'react-hot-toast';

import { dataReducer, initialState } from '../reducer/dataReducer';
import dataServices from '../services/dataServices';
import orderService from '../services/orderServices';
import bookService from '../services/bookServices';
import { notificationMessages } from '../constant/constants';
import { useAuth } from './AuthProvider';
import { useLocation, useNavigate } from 'react-router-dom';

const DataContext = createContext();

export function DataProvider({ children }) {
	const [state, dispatch] = useReducer(dataReducer, initialState);
	const [loading, setLoading] = useState(false);
	//const [error, setError] = useState(false);
	const { auth } = useAuth();
	const navigate = useNavigate();
	const location = useLocation();

	// function timeout(delay) {
	// 	return new Promise((res) => setTimeout(res, delay));
	// }

	// const handleSubmitImage = async (event, bookId, fileToUpload) => {
	// 	event.preventDefault();
	// 	if (!fileToUpload) {
	// 		toast.error('please provide an image');
	// 		return;
	// 	}
	// 	const formData = new FormData();
	// 	formData.append('file', fileToUpload);

	// 	try {
	// 		const response = await bookService.bookUplaodImage(
	// 			bookId,
	// 			formData
	// 		);
	// 		if (response.status !== 200) {
	// 			toast.error('Internal Server Error');
	// 			throw Error('Internal Server Error');
	// 			return;
	// 		}
	// 		const newBooks = state.allBooksFromApi.map((b) => {
	// 			if (b._id === bookId) {
	// 				return {
	// 					...response.data.book,
	// 					is_stock:
	// 						response.data.book.availableCount > 0
	// 							? true
	// 							: false,
	// 				};
	// 			}
	// 			return b;
	// 		});
	// 		toast.success(`Book updated successfully!`);
	// 		dispatch({
	// 			type: 'GET_ALL_BOOKS_FROM_API',
	// 			payload: newBooks,
	// 		});
	// 		toast.success('Image is updated successfully!');
	// 	} catch (error) {
	// 		toast.error('catch Error');
	// 	}
	// };
	// Book add:
	const addBook = async (formData) => {
		if (auth.isAuth) {
			try {
				setLoading(true);
				//setError('');
				//await timeout(4000);
				const response = await bookService.addBook(formData);

				if (response.status === 201 || response.status === 200) {
					if (response.data.status === 'success') {
						let nBook = response.data.data;
						nBook.is_stock =
							nBook.availableCount > 0 ? true : false;
						const newBooks = state.allBooksFromApi.concat([nBook]);
						toast.success(notificationMessages.bookAddSuccess);
						dispatch({
							type: 'GET_ALL_BOOKS_FROM_API',
							payload: newBooks,
						});
						setLoading(false);
						return true;
					} else {
						toast.error(
							notificationMessages.bookAddFailed +
								` ` +
								response.data.message
						);
						setLoading(false);
						return false;
					}
				} else {
					toast.error(notificationMessages.bookAddFailed);
					setLoading(false);
					return false;
				}
			} catch (error) {
				setLoading(false);
				toast.error(JSON.stringify(error.response.data));
				return false;
			}
		} else {
			toast(notificationMessages.loginRequiredMsg);
			navigate('/login', { state: { from: location } });
		}
	};
	// Books & Genres
	const getBooks = async () => {
		try {
			//setError(false);
			const response = await bookService.getAllBooks();
			if (response.request.status === 200) {
				if (response.data.status === 'success') {
					//const books = response.data.books.map((book) => ({
					const books = response.data.data.map((book) => ({
						...book,
						is_stock: book.availableCount > 0 ? true : false,
					}));
					dispatch({
						type: 'GET_ALL_BOOKS_FROM_API',
						payload: books,
					});
					//toast.success('get books success');
				} else {
					// toast.error(
					// 	"can't return the books; " + response.data.message
					// );
					// toast.error('get books failed');
				}
			}
		} catch (error) {
			//setError(true);
		}
	};
	//
	const updateBook = async (book) => {
		if (auth.isAuth) {
			try {
				setLoading(true);
				//setError('');

				const response = await bookService.updateBook(book);
				if (response.status === 200) {
					if (response.data.status === 'success') {
						const updateBook = response.data.data;
						const newBooks = state.allBooksFromApi.map((b) => {
							if (b._id === book._id) {
								return {
									...updateBook,
									is_stock:
										updateBook.availableCount > 0
											? true
											: false,
								};
							}
							return b;
						});
						toast.success(notificationMessages.bookUpdateSuccess);
						dispatch({
							type: 'GET_ALL_BOOKS_FROM_API',
							payload: newBooks,
						});
					} else {
						toast.error(
							notificationMessages.bookUpdateFailed +
								' ' +
								response.data.message
						);
						//toast.error('update book failed');
					}
				} else {
					toast.error(notificationMessages.bookUpdateFailed);
				}
			} catch (error) {
				//const err = JSON.stringify(error.response.data);
				setLoading(false);
				toast.error(error.response.data.message);
			} finally {
				setLoading(false);
			}
		} else {
			toast(notificationMessages.loginRequiredMsg);
			navigate('/login', { state: { from: location } });
		}
	};
	const deleteBook = async (book) => {
		if (auth.isAuth) {
			try {
				setLoading(true);
				//setError('');
				const response = await bookService.deleteBook(book._id);

				if (response.status === 200) {
					if (response.data.status === 'success') {
						const newBooks = state.allBooksFromApi.filter(
							(b) => b._id !== book._id
						);
						toast.success(notificationMessages.bookDeleteSuccess);
						dispatch({
							type: 'GET_ALL_BOOKS_FROM_API',
							payload: newBooks,
						});
					} else {
						toast.error(
							notificationMessages.bookDeleteFailed +
								' ' +
								response.data.message
						);
						//toast.error('update book failed');
					}
				} else {
					toast.error(notificationMessages.bookDeleteFailed);
				}
			} catch (error) {
				setLoading(false);
				toast.error(JSON.stringify(error.response.data));
			} finally {
				setLoading(false);
			}
		} else {
			toast(notificationMessages.loginRequiredMsg);
			navigate('/login', { state: { from: location } });
		}
	};

	//Genre
	const getGenres = async () => {
		try {
			const response = await dataServices.getAllGenres();
			if (response.request.status === 200) {
				dispatch({
					type: 'GET_ALL_GENRES',
					payload: response.data.data, //.genres,
				});
			}
		} catch (error) {
			console.error('getGenres', error);
		}
	};
	const addGenre = async (formData) => {
		if (auth.isAuth) {
			try {
				setLoading(true);
				//setError('');
				//await timeout(4000);
				const response = await dataServices.addGenre(formData);

				if (response.status === 201 || response.status === 200) {
					if (response.data.status === 'success') {
						let nGenre = response.data.data;

						const genres = state.allGenres.concat([nGenre]);
						dispatch({
							type: 'GET_ALL_GENRES',
							payload: genres, //.genres,
						});
						toast.success(notificationMessages.genreAddSuccess);
						setLoading(false);
						return true;
					} else {
						toast.error(
							notificationMessages.genreAddFailed +
								` ` +
								response.data.message
						);
						setLoading(false);
						return false;
					}
				} else {
					toast.error(notificationMessages.genreAddFailed);
					setLoading(false);
					return false;
				}
			} catch (error) {
				setLoading(false);
				toast.error(JSON.stringify(error.response.data));
				return false;
			}
		} else {
			toast(notificationMessages.loginRequiredMsg);
			navigate('/login', { state: { from: location } });
		}
	};

	const editGenre = async (genre) => {
		if (auth.isAuth) {
			try {
				setLoading(true);
				//setError('');
				//await timeout(4000);
				const response = await dataServices.editGenre(genre);

				if (response.status === 200) {
					if (response.data.status === 'success') {
						const nGenre = response.data.data;

						//const genres = state.allGenres.concat([nGenre]);
						dispatch({
							type: 'GET_ALL_GENRES',
							payload: nGenre,
						});
						toast.success(notificationMessages.genreUpdateSuccess);
						setLoading(false);
						return true;
					} else {
						toast.error(
							notificationMessages.genreUpdateFailed +
								` ` +
								response.data.message
						);
						setLoading(false);
						return false;
					}
				} else {
					toast.error(notificationMessages.genreUpdateFailed);
					setLoading(false);
					return false;
				}
			} catch (error) {
				setLoading(false);
				toast.error(JSON.stringify(error.response.data));
				return false;
			}
		} else {
			toast(notificationMessages.loginRequiredMsg);
			navigate('/login', { state: { from: location } });
		}
	};
	const deleteGenre = async (_id) => {
		if (auth.isAuth) {
			try {
				setLoading(true);
				//setError('');
				//await timeout(4000);
				const response = await dataServices.deleteGenre(_id);

				if (response.status === 200) {
					if (response.data.status === 'success') {
						const nGenre = response.data.data;

						//const genres = state.allGenres.concat([nGenre]);
						dispatch({
							type: 'GET_ALL_GENRES',
							payload: nGenre,
						});
						toast.success(notificationMessages.genreDeleteSuccess);
						setLoading(false);
						return true;
					} else {
						toast.error(
							notificationMessages.genreDeleteFailed +
								` ` +
								response.data.message
						);
						setLoading(false);
						return false;
					}
				} else {
					toast.error(notificationMessages.genreDeleteFailed);
					setLoading(false);
					return false;
				}
			} catch (error) {
				setLoading(false);
				toast.error(JSON.stringify(error.response.data));
				return false;
			}
		} else {
			toast(notificationMessages.loginRequiredMsg);
			navigate('/login', { state: { from: location } });
		}
	};
	// Orders
	const getOrdersList = async () => {
		if (auth.isAuth) {
			//await timeout(4000);
			try {
				//setLoading(true);
				const response = await orderService.getAllOrderService();
				if (response.status === 200) {
					if (response.data.status === 'success') {
						let orders = response.data.data;
						orders = orders.map((o) => ({
							...o,
							booksInfo: JSON.parse(o.booksInfo),
							user: JSON.parse(o.user),
							address: JSON.parse(o.address),
						}));
						//setLoading(false);
						dispatch({
							type: 'SET_ORDERS',
							payload: orders,
						});
						//toast.success('order success');
					} else {
						// toast.error(
						// 	"can't return the order; " + response.data.message
						// );
						// toast.error('order failed');
					}
				}
			} catch (error) {
				//setLoading(false);
				// toast.error('order failed');
			}
		}
	};
	const updateOrderStatusHandler = async (_id, newStatus) => {
		if (auth.isAuth) {
			try {
				setLoading(true);
				//setError('');
				const response = await orderService.adminUpdateOrderService(
					_id,
					newStatus
				);

				if (response.status === 200) {
					if (response.data.status === 'success') {
						let neworder = response.data.data;
						neworder = {
							...neworder,
							booksInfo: JSON.parse(neworder.booksInfo),
							user: JSON.parse(neworder.user),
							address: JSON.parse(neworder.address),
						};
						const newOrders = state.orders.map((o) => {
							if (o.orderId === _id) {
								return neworder;
							}
							return o;
						});
						toast.success(
							`Order ${_id} ${newStatus} successfully!`
						);
						dispatch({
							type: 'SET_ORDERS',
							payload: newOrders, //response.data.orders,
						});
					} else {
						toast.error(response.data.message);
						toast.error('update order failed');
					}
				} else {
					toast.error(`${notificationMessages.orderFailed}!`);
				}
			} catch (error) {
				setLoading(false);
				toast.error(error.response.data.message);
			} finally {
				setLoading(false);
			}
		} else {
			toast(`update order requires login!`);
			navigate('/login', { state: { from: location } });
		}
	};

	//Users

	/*const getBookById = async (bookId) => {
		const response = await dataServices.getBookById(bookId);
		const book = response.data.book;
		dispatch({ type: 'SET_SELECTED_BOOK', payload: book });
	};*/

	useEffect(() => {
		if (auth.isAuth) {
			const load = async () => {
				setLoading(true);
				//await timeout(4000);
				//setError('');
				getGenres();
				getBooks();
				getOrdersList();
				setLoading(false);
			};
			load();
		}
	}, [auth]);

	return (
		<DataContext.Provider
			value={{
				state,
				setLoading,
				getOrdersList,
				addGenre,
				editGenre,
				deleteGenre,
				dispatch,
				loading,
				//acceptOrderHandler,
				//rejectOrderHandler,
				updateOrderStatusHandler,
				//updateBookAvailability,
				addBook,
				updateBook,
				deleteBook,
				//handleSubmitImage,
			}}
		>
			{children}
		</DataContext.Provider>
	);
}

export const useData = () => useContext(DataContext);
