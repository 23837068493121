import axios from 'axios';
import { backAPI } from '../constant/constants';

const adminAllBooks = backAPI.adminAllBooks; //'https://glare.cu.ma/book/books/book.php';
const adminAddBook = backAPI.adminAddBook;
const adminUpdateBook = backAPI.adminUpdateBook;
const adminDeleteBook = backAPI.adminDeleteBook;

const auth_header = () => ({
	//'Access-Control-Allow-Origin': '*',
	Authorization: 'Bearer ' + window.localStorage.getItem('token'),
});
const auth_header_upload = () => ({
	'content-type': 'multipart/form-data',
	authorization: 'Bearer ' + window.localStorage.getItem('token'),
});

const getAllBooks = async () =>
	await axios.get(adminAllBooks, { headers: auth_header() });

// const setBookAvailability = async (bookId, newStatus) =>
// 	await axios.put(
// 		bookAvailableURL,
// 		{ bookId, newStatus },
// 		{ headers: auth_header() }
// 	);

const addBook = async (formData) =>
	await axios.post(adminAddBook, formData, { headers: auth_header_upload() });

const updateBook = async (book) =>
	//await axios.put(adminUpdateBook, { ...book }, { headers: auth_header() });
	await axios.post(adminUpdateBook, { ...book }, { headers: auth_header() });

const deleteBook = async (bookId) =>
	await axios.post(adminDeleteBook, { bookId }, { headers: auth_header() });
// await axios.delete(URL, {
// 	data: { bookId },
// 	headers: auth_header(),
// });

// const bookUplaodImage = async (bookId, formData) => {
// 	return await axios({
// 		method: 'post',
// 		url: bookUpladImageURL + '/' + bookId,
// 		data: formData,
// 		headers: auth_header_upload(),
// 	});
// };

const bookService = {
	getAllBooks,
	addBook,
	updateBook,
	deleteBook,
	//bookUplaodImage,
	//setBookAvailability,
};

export default bookService;
