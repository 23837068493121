import React from 'react';
import { Outlet } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useData } from '../../contexts/DataProvider';
//import { useState } from 'react';

import './UserProfile.css';
import { useAuth } from '../../contexts/AuthProvider';
import { paths, words } from '../../constant/constants';

export const UserProfile = () => {
	const { loading } = useData();
	const { currentPage, setCurrentPage } = useAuth();

	return (
		!loading && (
			<div>
				<div className='user-profile-container'>
					<div className='link-container'>
						<Link
							style={{
								color:
									currentPage === 'profile'
										? 'black'
										: 'grey',
							}}
							onClick={() => setCurrentPage('profile')}
							to={paths.profilePath}
						>
							{words.profilePage}
						</Link>
						<Link
							style={{
								color:
									currentPage === 'orders' ? 'black' : 'grey',
							}}
							onClick={() => setCurrentPage('orders')}
							to={paths.profileOrdersPath}
						>
							{words.ordersPage}
						</Link>
						<Link
							style={{
								color:
									currentPage === 'addresses'
										? 'black'
										: 'grey',
							}}
							onClick={() => setCurrentPage('addresses')}
							to={paths.profileAddressesPath}
						>
							{words.addressesPage}
						</Link>
					</div>
					<Outlet />
				</div>
			</div>
		)
	);
};
