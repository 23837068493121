import React, { useMemo, useCallback } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { useData } from '../../contexts/DataProvider';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';

//nested data is ok, see accessorKeys in ColumnDef below
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import { useNavigate } from 'react-router-dom';

import { paths, words } from '../../constant/constants';

const Genres = () => {
	const navigate = useNavigate();
	const { state, loading, editGenre, deleteGenre } = useData();
	const list = //userDataState.orders.map((o) => {
		state.allGenres.map((g) => {
			return {
				//key: o._id,
				_id: g._id,
				genre: g.genre,
			};
		});
	const columns = useMemo(
		() => [
			{
				accessorKey: 'genre', //access nested data with dot notation
				header: words.genre,
				size: 200,
			},
		],
		[]
	);

	const handleSaveRow = ({ exitEditingMode, row, values }) => {
		const newGenre = {
			_id: row.original._id,
			...values,
		};
		editGenre(newGenre);
		exitEditingMode();
	};

	const handleDeleteRow = useCallback(
		(row) => {
			if (
				!window.confirm(
					`Are you sure you want to delete ${row.getValue('genre')}`
				)
			) {
				return;
			}
			deleteGenre(row.original._id);
		},
		[list]
	);
	return (
		<MaterialReactTable
			columns={columns}
			data={list}
			enableEditing
			onEditingRowSave={handleSaveRow}
			renderRowActions={({ row, table }) => (
				<Box
					sx={{
						display: 'flex',
						gap: '1rem',
						justifyContent: 'center',
					}}
				>
					<Tooltip arrow placement='left' title='Edit'>
						<IconButton onClick={() => table.setEditingRow(row)}>
							<Edit />
						</IconButton>
					</Tooltip>
					<Tooltip arrow placement='right' title='Delete'>
						<IconButton
							color='error'
							onClick={(e) => {
								e.preventDefault();
								handleDeleteRow(row);
							}}
						>
							<Delete />
						</IconButton>
					</Tooltip>
				</Box>
			)}
			enableStickyHeader
			muiTableHeadCellProps={{
				align: 'center',
				sx: (theme) => ({
					background: '#f5f7f9', //'rgba(52, 210, 235, 0.1)',
					borderRight: '1px solid rgba(224,224,224,1)',
					color: theme.palette.text.primary,
					direction: 'rtl',
					justifyContent: 'center',
				}),
			}}
			muiTableBodyCellProps={{ align: 'center', direction: 'rtl' }}
			enableStickyFooter
			renderTopToolbarCustomActions={({ table }) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					{/* <Typography variant='h4'>Books:</Typography> */}
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							gap: '1rem',
							p: '0.5rem',
							//flexWrap: 'wrap',
						}}
					>
						<Button
							color='success'
							onClick={() => navigate(paths.addGenreAdmin)}
							variant='outlined'
							style={{
								fontWeight: 'bold',
							}}
							startIcon={<CategoryOutlinedIcon />}
						>
							{words.genreNew}
						</Button>
					</Box>
				</div>
			)}
			state={{ isLoading: loading }}
		/>
	);
};

export default Genres;
