import React from 'react';
import { Footer } from '../../components/Footer/Footer';
//import { HeroSection } from './components/HeroSection/HeroSection';
//import { VideosSection } from './components/VideosSection/VideosSection';
import { HeroVideo } from './components/HeroVideo/HeroVideo';
import { useData } from '../../contexts/DataProvider';
import HomeCarousles from './components/HomeCarousles/HomeCarousles';

export const Home = () => {
	const { loading } = useData();
	return (
		!loading && (
			<div className='home-page'>
				<div className='hero'>
					<HeroVideo />
					{/* <HeroSection />
					<VideosSection /> */}
					<HomeCarousles />
					<Footer />
				</div>
			</div>
		)
	);
};
