import './Header.css';
//import { CgHeart } from 'react-icons/cg';
import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { RxHamburgerMenu } from 'react-icons/rx';
import { RxCross2 } from 'react-icons/rx';
import { GrSearch } from 'react-icons/gr';
import { useData } from '../../contexts/DataProvider';
import { useAuth } from '../../contexts/AuthProvider';
//import { CgShoppingCart } from 'react-icons/cg';
import { AiOutlineUser } from 'react-icons/ai';

//import { useUserData } from '../../contexts/UserDataProvider';
//import { SiTaichilang } from 'react-icons/si';
import { paths, words } from '../../constant/constants';

export const Header = () => {
	const { auth } = useAuth();
	const { dispatch } = useData();
	const navigate = useNavigate();
	//const { userDataState } = useUserData();
	const [showHamburger, setShowHamburger] = useState(true);
	const getActiveStyle = ({ isActive }) => {
		return { color: isActive ? '#00a99d' : '' };
	};

	/*const totalProductsInCart = userDataState.cartBooks?.reduce((acc, curr) => {
		return acc + curr.qty;
	}, 0);*/
	//const totalProductsInCart = userDataState.cartBooks?.length;
	//const isProductInCart = () => (Number(totalProductsInCart) ? true : false);

	return (
		<nav className='navBar-bk'>
			<div className='nav-logo-home-button'>
				<NavLink style={getActiveStyle} to={paths.indexPath}>
					<img
						className='logo'
						src='/assets/images/logo.png'
						alt={words.appName}
					/>
					{/* <SiTaichilang />
					<span className='brand-name'>{`  ${words.appName}`}</span> */}
				</NavLink>
			</div>

			<div className='nav-input-search'>
				<input
					onChange={(e) =>
						dispatch({ type: 'SEARCH', payload: e.target.value })
					}
					onKeyDown={(e) => {
						e.key === 'Enter' && navigate(paths.booksListPath);
					}}
					placeholder={words.searchPlaceholder}
				/>
				<button>
					<GrSearch />
				</button>
			</div>

			<div
				className={
					!showHamburger
						? 'nav-link-container-mobile nav-link-container'
						: 'nav-link-container'
				}
			>
				<NavLink
					onClick={() => setShowHamburger(true)}
					style={getActiveStyle}
					to={paths.genresAdmin}
				>
					{words.genresNav}
				</NavLink>
				<NavLink
					onClick={() => setShowHamburger(true)}
					style={getActiveStyle}
					to={paths.booksAdmin}
				>
					{words.exploreAdmin}
				</NavLink>

				<NavLink
					onClick={() => setShowHamburger(true)}
					style={getActiveStyle}
					to={paths.ordersPath}
				>
					{/* <span>{!showHamburger ? 'Cart' : ''}</span> */}
					{/* <CiReceipt size={25} className='cart' /> */}
					{words.navOrders}
					{/* {
						<span className='cart-count cart-count-mobile'>
							{' '}
							{pendingOrders}{' '}
						</span>
					} */}
				</NavLink>
				<NavLink
					onClick={() => setShowHamburger(true)}
					style={getActiveStyle}
					to={auth.isAuth ? paths.profilePath : paths.loginPath}
				>
					{!auth.isAuth ? words.loginNav : <AiOutlineUser />}
				</NavLink>
			</div>
			{showHamburger && (
				<div
					className='hamburger-icon'
					onClick={() => setShowHamburger(false)}
				>
					<RxHamburgerMenu size={20} />
				</div>
			)}
			{!showHamburger && (
				<div
					className='cross-tab-icon cross-tab-icon-mobile'
					onClick={() => setShowHamburger(true)}
				>
					<RxCross2 color={'rgb(106, 106, 65)'} size={25} />
				</div>
			)}
		</nav>
	);
};
