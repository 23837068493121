import './DeliveryAddress.css';
import { useUserData } from '../../../../contexts/UserDataProvider';
//import { v4 as uuid } from 'uuid';
// import { addOrderService } from '../../../../services/order-services/addOrderService';
// import { clearCartService } from '../../../../services/cart-services/clearCartService';

import React from 'react';
import { toast } from 'react-hot-toast';
import { useAuth } from '../../../../contexts/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { paths, words } from '../../../../constant/constants';

export const DeliveryAddress = () => {
	const { userDataState, clearCartHandler, addOrderHandler } = useUserData();

	const {
		//cartBooks,
		orderDetails: {
			//cartItemsDiscountTotal,
			orderAddress,
		},
	} = userDataState;

	//	const totalAmount = cartItemsDiscountTotal;

	const navigate = useNavigate();

	const { setCurrentPage } = useAuth();

	const successHandler = async () => {
		const addOrderResutl = await addOrderHandler();
		if (addOrderResutl) {
			clearCartHandler();
			setCurrentPage('orders');
			navigate(paths.ordersPath);
		}
	};

	const placeOrderHandler = () => {
		if (orderAddress) {
			successHandler();
		} else {
			toast('Please select an address!');
		}
	};

	return (
		<div className='delivery-address-container'>
			<p>{words.orderDeliveringTo}</p>

			<div className='delivery-address-description'>
				<span className='name'>
					{words.addressFormName}:{' '}
					{userDataState.orderDetails?.orderAddress?.name}
				</span>
				<span className='address'>
					{words.fullAddress}:{' '}
					{orderAddress.country ? `${orderAddress.country},` : ''}
					{orderAddress.state ? `${orderAddress.state},` : ''}
					{orderAddress.city ? `${orderAddress.city},` : ''}
					{orderAddress.street ? `${orderAddress.street}` : ''}
				</span>
				<span className='contact'>
					{words.addressFormPhone}: {orderAddress?.phone}
				</span>
				<button
					onClick={placeOrderHandler}
					className='place-order-btn main_btn_bk'
				>
					{words.checkoutPlaceOrder}
				</button>
			</div>
		</div>
	);
};
