// import React, { useEffect } from 'react';
// import './Login.css';

// import { useAuth } from '../../../contexts/AuthProvider.js';
// import { useData } from '../../../contexts/DataProvider.js';
// import { useNavigate } from 'react-router-dom';
// import { paths, words } from '../../../constant/constants';

// export const Login = () => {
// 	const navigate = useNavigate();
// 	const { loading } = useData();
// 	const { auth, error, loginHandler } = useAuth();

// 	useEffect(() => {
// 		if (auth.isAuth) {
// 			navigate(paths.profilePath);
// 		}
// 	}, [auth]);
// 	//const { email, password } = loginCredential;

// 	return (
// 		!loading && (
// 			<div className='login-container'>
// 				<h2>{words.login}</h2>
// 				<form onSubmit={loginHandler} className='login-body'>
// 					{error && <span className='error'>{error}</span>}
// 					<div className='login-btn-container '>
// 						<button type='submit' className='navBar-bk'>
// 							<img
// 								src={'/assets/icons/google.svg'}
// 								alt='google logo'
// 								style={{ width: '20px', marginRight: '20px' }}
// 							/>
// 							<span>{words.login}</span>
// 						</button>
// 					</div>
// 				</form>
// 			</div>
// 		)
// 	);
// };

import React, { useEffect, useState } from 'react';
import './Login.css';
//import { Link } from 'react-router-dom';
import { BsEyeSlash } from 'react-icons/bs';
import { BsEye } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthProvider.js'; //contexts/AuthProvider.jsx';
import { useData } from '../../../contexts/DataProvider.js';
import { paths, words } from '../../../constant/constants';

export const Login = () => {
	const { loading } = useData();
	const [hidePassword, setHidePassword] = useState(true);
	const { auth, error, loginCredential, setLoginCredential, loginHandler } =
		useAuth();

	const { email, password } = loginCredential;
	const navigate = useNavigate();
	useEffect(() => {
		if (auth.isAuth) {
			navigate(paths.profilePath);
		}
	}, [auth]);
	return (
		!loading && (
			<div className='login-container'>
				<h2>{words.login}</h2>
				<form
					onSubmit={(e) => loginHandler(e, email, password)}
					className='login-body'
				>
					<div className='email-container'>
						<label htmlFor='email'>Email</label>
						<input
							value={loginCredential.email}
							required
							onChange={(e) =>
								setLoginCredential({
									...loginCredential,
									email: e.target.value,
								})
							}
							id='email'
							placeholder='Email Address'
							type='email'
						/>
					</div>

					<div className='password-container'>
						<label htmlFor='password'>Password</label>
						<div className='input-container'>
							<input
								value={loginCredential.password}
								required
								onChange={(e) =>
									setLoginCredential({
										...loginCredential,
										password: e.target.value,
									})
								}
								id='password'
								placeholder='Password'
								type={hidePassword ? 'password' : 'text'}
							/>
							{!hidePassword ? (
								<BsEye
									className='hide-show-password-eye'
									onClick={() =>
										setHidePassword(!hidePassword)
									}
								/>
							) : (
								<BsEyeSlash
									className='hide-show-password-eye'
									onClick={() =>
										setHidePassword(!hidePassword)
									}
								/>
							)}
						</div>
					</div>

					{/* <div className='remember-me-container'>
						<div>
							<input name='remember-me' type='checkbox' />
							<label htmlFor='remember-me'>
								Keep me signed in
							</label>
						</div>

						<p>Forgot your password?</p>
					</div> */}
					{error && <span className='error'>{error}</span>}
					<div className='login-btn-container'>
						<button className='navBar-bk' type='submit'>
							{words.login}
						</button>
					</div>
				</form>
			</div>
		)
	);
};
