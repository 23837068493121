import axios from 'axios';
import { backAPI } from '../constant/constants';

const auth_header = () => ({
	//'Access-Control-Allow-Origin': '*',
	Authorization: 'Bearer ' + window.localStorage.getItem('token'),
});
const genresApi = backAPI.generalAllGenres; //'https://glare.cu.ma/book/genre/view.php';
const adminAddGenre = backAPI.adminAddGenre;
const adminEditGenre = backAPI.adminEditGenre;
const adminDeleteGenre = backAPI.adminDeleteGenre;

const booksApi = backAPI.generalAllBooks; //'https://glare.cu.ma/book/books/book.php';

const getAllGenres = async () => await axios.get(genresApi); //(dbConstants.genres);

const addGenre = async (formData) =>
	await axios.post(adminAddGenre, formData, {
		headers: auth_header(),
	});
const editGenre = async (genre) =>
	await axios.post(
		adminEditGenre,
		{ ...genre },
		{
			headers: auth_header(),
		}
	);
const deleteGenre = async (_id) =>
	await axios.post(
		adminDeleteGenre,
		{ _id },
		{
			headers: auth_header(),
		}
	);

const getAllBooks = async () => await axios.get(booksApi); //(dbConstants.adminBooks);

// const getBookById = async (bookId) => {
// 	return await axios.post(dbConstants.bookById, { bookId });
// };

const dataServices = {
	getAllGenres,
	addGenre,
	editGenre,
	deleteGenre,
	getAllBooks,
	//getBookById,
};

export default dataServices;
